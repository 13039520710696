.center {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.auth form{
  display: flex;
  flex-direction: column;
}

.auth__success{
  background-color: hsl(147, 79%, 87%);
  margin-bottom: 20px;
}

.auth__error{
  padding: 0 0;
  margin-bottom: 20px;
  color: white;
  background: var(--primary-color);
  font-size: 13px;
  padding: 8px 12px;
  border-radius: 5px;
  text-align: center;
  max-width: 320px;
}


.auth {
  position: relative;
}

.box {
  background: white;
  border-radius: 16px;
  width: 320px;
  border: 1px solid var(--color-gray);
  color: var(--text-color);
  padding: 24px;
  text-align: center;
  font-size: 13px;
}

.box h1 {
  font-weight: bold;
  padding: 0 0 20px;
}


input {
  border: none;
  border-radius: 5px;
  background: var(--input-bg);
  margin-bottom: 12px;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  padding-left: 8px;
  margin-bottom: 16px;
}

button, .button {
  background: black;
  border-radius: 16px;
  padding: 12px 12px;
  color: white !important;
  flex-grow: 0;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 12px;
  margin: 0 64px 12px;
  outline: none;
  border: none;
}

.box p {
  font-size: 11px;
}

.box a {
  color: var(--primary-color);
}

.btn-register-big {
  background: black;
  margin: 64px auto;
  border-radius: 28px;
  white-space: nowrap;
  height: 72px;
  line-height: 76px;
  font-size: 15px;
}
.btn-register-big a {
  display: block;
  
  padding: 0 32px;
}

.bottom-area {
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
}

.logo {
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
}

.spacer {
  height: 48px;
  width: 48px;
  margin-bottom: 64px;
}