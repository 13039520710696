html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, a:visited {
  text-decoration: none;
  color: inherit; }


:root {
  --bg-color: #fff;
  --bg-color-dark: #000;
  --text-color: #303030;
  --text-color-gray: #EEEEEE;
  --color-gray: #ADADAD;
  --color-gray-dark: #787878;
  --input-bg: #D9D9D9;
  --primary-color: #E62695;
  --secondary-color: #67DAA8;
}

.nav {
  position: absolute;
  top: 8px;
  right: 0;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 480px) {
  .nav {
    right: 12px;
    top: 16px;
  }
}

.nav a {
  margin-right: 16px;
  margin-left: 16px;
  font-size: 12px;
  color: white;
}

.nav .nav-button {
  background: black;
  border-radius: 16px;
  padding: 12px 12px;
  display: block;
}

.logo {
  position: relative;
  margin-bottom: 64px;
}

.logo img {
  width: 48px;
  height: 48px;
}

.text-page {
  background: white;
  overflow: auto;
  height: 100vh;
  position: relative;
}

.text-body {
  max-width: 960px;
  padding: 32px 24px 120px;
  margin: 0 auto;
  line-height: 1.5;
}

.text-body h1 {
  font-weight: bold;
  font-size: 24px;
}

.text-body p {
  margin: 16px 0;
}

.text-body h2 {
  font-weight: bold;
  font-size: 18px;
  margin: 16px 0;

}

.text-body h3 {
  font-weight: bold;
  margin: 16px 0;
}

.text-body a {
  color: var(--primary-color);
}

.text-body ul {
  list-style-type: disc;
  list-style-position: initial;
  margin-left: 16px;
}

