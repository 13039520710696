.doc {
    width: 100vw;
    min-height: 100vh;
    display: flex;
}


@media (prefers-color-scheme: dark) {
    .doc {
        background: black;
        color: white;
    }
}

.doc-sidepanel {
    width: 240px;
    padding: 16px;
    flex-grow: 0;
    flex-shrink: 0;
}

@media screen and (max-width: 640px) {
    .doc {
      display: block;
    }

    .doc-sidepanel {
        padding: 24px 16px 16px 24px;
    }
}


.doc-logo {
    margin-bottom: 8px;
}

.topics {
    font-size: 13px;
}

.topic-title {
    padding: 16px 0 8px 0;
    cursor: pointer;
}

.topic li {
    margin-bottom: 8px;
    padding-left: 12px;
    cursor: pointer;
}

.topic-title:hover, .topic li:hover, ul.instructions a {
    color: var(--primary-color);
}

.doc-body {
    padding: 32px;
    max-width: 960px;
}

.doc-body h1 {
    padding: 24px 0;
    font-weight: 400;
    font-size: 48px;
}

.doc-body h2 {
    font-weight: 500;
    font-size: 24px;
    padding: 16px 0;
}

.doc-body p {
    line-height: 1.5;
    margin-bottom: 24px;
    font-size: 16px;
}

ul.instructions {
    margin-bottom: 24px;
}

ul.instructions li {
    list-style: disc;
    list-style-position: outside;
    margin: 0 0 8px 16px;
    line-height: 1.5;
}

ul.instructions span {
    font-style: italic;
}

.doc-body a {
    text-decoration: underline;
    color: var(--primary-color);
}

.doc-sidepanel span.active {
    color: var(--primary-color)
}