.verifyEmail {
  font-size: 13px;
  width: 480px;
  padding-bottom: 16px;
  padding-top: 48px;
}

.verifyEmail p {
  font-size: 15px;
  margin-bottom: 16px;
  line-height: 20px;
}

.verifyEmail span {
  color: var(--primary-color);
}

.verifyEmail button {
  margin-top: 8px;
}

