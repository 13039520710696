.landing-splash {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(30deg, #FD6637 5%, #172e96 50%);
    color: white;
}

.waves {
    position: absolute;
    width: 200%;
    height: 150%;
    display: block;
    top:25%;
    left: -25%; 
    transform: rotate(30deg);
    background-image: radial-gradient(yellow, transparent);
    -webkit-mask-image: radial-gradient(black 40%, transparent 70%);
    mask-image: radial-gradient(black 40%, transparent 70%);
}
  
.waves:before {
    position: absolute;
    content: "";
    width: 120%;
    height: 100%;
    top: 15%;
    left: -25%;
    background-image: radial-gradient(#1EFFF1, transparent); 
    transform: rotate(-15deg);
    -webkit-mask-image: radial-gradient(black 20%, transparent 70%);
    mask-image: radial-gradient(black 20%, transparent 70%);
    filter: contrast(1000%) brightness(80%) saturate(50%);
    animation: rotateShape 90s infinite linear;
}
  
.waves:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: -20%;
    left: 5%;
    background-image: radial-gradient(#9c19fad1 , transparent);
    transform: rotate(-45deg);
    -webkit-mask-image: radial-gradient(black 20%, transparent 70%);
    mask-image: radial-gradient(black 20%, transparent 70%);
    filter: contrast(400%) brightness(150%) saturate(50%);
    animation: rotateShape 60s infinite linear; 
}

@keyframes rotateShape {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.product {
    perspective: 1000px;
    perspective-origin: 50% 50%;
    height: 100vh;
    width: 100vh;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

 .product-img {
    background: url(./img/beautyshot.png) no-repeat center center;
    background-size: contain;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(5deg) rotateY(-17deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(-9deg) skewY(0deg);
    -webkit-mask-image: linear-gradient(black 40%, transparent 70%);
    mask-image: linear-gradient(black 40%, transparent 70%);
    filter: saturate(140%);
 }
 
.product-img:after {
    width: 93%;
    display: block;
    position: absolute;
    content: "";
    padding-top:20%;
    bottom:20%;
    left: 3.5%;
    mix-blend-mode: multiply;
    background: linear-gradient(to top, black, transparent);
    filter: contrast(200%) brightness(1000%);
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(4vh + 16px);
    flex-grow: 0;
    position: relative;
    z-index: 15;
}
 
.heading img {
    width: 48px;
    height: 48px;
}

.heading .name {
    font-weight: 400;
    font-size: 18px;
    padding: 16px 0;
}

.splash-text {
    display: block;
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 16px;
    line-height: 1.6;
    z-index: 100;
}

.download {
    display: block;
    background: black;
    padding: 16px 16px 12px;
    margin: 32px auto 0;
    border-radius: 28px;
    min-width: 120px;
    max-width: 200px;
}

.download span {
    display: block;
    font-size: 12px;
    opacity: .5;
}



.landing-body .section {
    max-width: 960px;
    margin: 0 auto 32px;
    display: flex;
}

.landing-body h1 {
    text-align: center;
    margin: 0 auto;
    padding: 96px 0;
    max-width: 640px;
    line-height: 1.5;
    font-weight: 500;
    font-size: 24px;
}

.section-text p {
    margin: 16px 0;
    font-size: 16px;
    line-height: 1.5;
}

@media (prefers-color-scheme: dark) {
    .landing-body {
        background: black;
        color: white;
    }
}

.section-img {
    width: 50%;
    padding-top: 50%;
    background: green;
    flex-shrink: 0;
    background-size: cover;
    background-position: center center;
}

.section-text {
    padding: 32px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


@media screen and (max-width: 640px) {
    .landing-body .section {
      display: block;
    }

    .section-img {
        margin: 0 auto;
        width: 100%;
        padding-top: 100%;
    }
}