
.profile.box {
  font-size: 13px;
  width: 480px;
  padding-bottom: 24px;
  padding-top: 48px;
}

.profile.box p {
  font-size: 15px;
  margin-bottom: 16px;
  line-height: 20px;
}

.profile.box span {
  color: var(--primary-color);
}

.profile.box button {
  margin-top: 8px;
}

.profile-pic {
  width: 80px;
  height: 80px;
  background: var(--secondary-color);
  color: white;
  border-radius: 100%;
  font-size: 64px;
  line-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.center-flex {
  display: flex;
  justify-content: center;  
}

.profile.box .button{
  margin: 0 160px 24px;
}

.profile.box span {
  cursor: pointer;
}
